<template>
  <section class="m-order-detail m-scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/merchant/icon-fanhui@2x.png" alt="back">
      <span :style="`opacity:${(this.offsetTop - 100) / 100};`">售后详情</span>
      <img src="@/assets/aftersale/icon_kefu@2x.png" alt="" @click="$router.push('/zh/customer')">
    </div>
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`"></div>

    <!-- 订单状态 -->
    <div class="order-status">
      <p>{{order.audit_status_title}}</p>
      <img v-if="order.audit_status == 0" src="@/assets/aftersale/step01.png" alt="step">
      <!-- <img v-if="order.audit_status == 1" src="@/assets/aftersale/step02.png" alt="step"> -->
      <img v-if="order.audit_status == 1 && order.after_sales_status != 3" src="@/assets/aftersale/step03.png" alt="step">
      <img v-if="order.audit_status == 2 || (order.audit_status == 1 && order.after_sales_status == 3)" src="@/assets/aftersale/step04.png" alt="step">
    </div>

    <!-- 物流信息 -->
    <div class="logistics">
      <div class="number van-hairline--bottom">
        <p>服务单号：{{order.title}}</p>
        <!-- <span>复制</span> -->
      </div>

      <div class="status-tips" v-if="order.audit_status == 0">
        <p>服务单已申请成功，请耐心等待商家审核</p>
        <!-- <p>您可以修改售后申请，商家会重新处理。</p> -->
        <!-- <div class="btns">
          <span class="btn">撤销申请</span>
          <span class="btn-active">修改申请</span>
        </div> -->
      </div>
      <div class="status-tips" v-if="order.audit_status == 1 && order.after_sales_status == 2">
        <p>商家已审核通过，我们将在2-3个工作日内为您处理退款。</p>
        <!-- <p>您可以修改售后申请，商家会重新处理。</p> -->
        <!-- <div class="btns">
          <span class="btn">撤销申请</span>
          <span class="btn-active">修改申请</span>
        </div> -->
      </div>

      <div class="status-tips" v-if="order.audit_status == 1 && order.after_sales_status == 1">
        <p>商家已审核通过，请您尽快发货</p>
        <!-- <p>如未及时填写，服务将在6天23小时28分21秒后关闭</p> -->
        <!-- <div class="btns">
          <span class="btn">撤销申请</span>
          <span class="btn-active" @click="handleComplete">填写发货单</span>
        </div> -->
      </div>

      <div class="status-tips" v-if="order.audit_status == 1 && order.after_sales_status == 3">
        <p>商家已退款，预计3-5个工作日内到账</p>
      </div>

      <div class="status-tips" v-if="order.audit_status == 1">
        <p>{{order.refuse_reason}}</p>
      </div>
    </div>

    <!-- 退款金额 -->
    <div class="price-info" v-if="step == 4">
        
        <div class="price-cell">
          <span>商品金额</span>
          <p><span>{{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_goods_price}}</span></p>
        </div>
        <div class="price-cell">
          <span>退税金额</span>
          <p><span>{{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_tax_price}}</span></p>
        </div>
        <div class="price-cell">
          <span>钱包退款</span>
          <p><span>{{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_wallet_price}}</span></p>
        </div>
        <div class="price-cell price-cell-active van-hairline--bottom">
          <span>退款总额</span>
          <p><span>{{order.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{order.total_price}}</span></p>
        </div>
        <div class="price-cell price-cell-active">
          <span>退款方式</span>
          <p>原支付返还</p>
        </div>
    </div>

    <!-- 发货单信息 -->
    <!-- <div class="order-info m-padding" v-if="(step == 3 || step == 4) && order.after_sales_status != 2">
        <div class="goods-title">发货单信息</div>
        <div class="info-cell">
          <span>快递单号</span>
          <p>2399494040040</p>
        </div>
        <div class="info-cell">
          <span>物流公司</span>
          <p>物流公司名</p>
        </div>
    </div> -->
    <!-- 地址信息 -->
    <div class="address-info m-padding" v-if="(step == 3 || step == 4) && order.after_sales_status != 2">
        <div class="number van-hairline--bottom" style="padding:0;margin:0">
          <p>商品寄回地址</p>
          <!-- <span>复制</span> -->
        </div>
        <p>
          <img src="@/assets/aftersale/icon_address.png" alt="address">
          <span>MoobyYoho</span>
          <span>7786806480</span>
        </p>
        <p>11121 Horseshoe Way Unit 133，Richmond，BC V7A 5G7</p>
    </div>

    <!-- 商品信息 -->
    <ul class="goods-list m-padding">
      <li class="goods-title"><router-link to>商品信息</router-link></li>
      <li class="goods-item" v-for="i in order.order_goods_all" :key="i.order_goods.goods_id">
        <router-link :to="`/zh/goods/detail/${i.order_goods.goods_id}`">
          <img :src="i.goods_sku_pic_url" alt="goods">
          <div>
            <p class="van-ellipsis">{{i.goods_name}}</p>
            <p>数量：{{i.after_sales_number}}</p>
            <p>单价：{{order.order_logistics.country == 507 ? 'US$':'C$'}}{{(i.goods_price / i.after_sales_number).toFixed(2)}}</p>
          </div>
        </router-link>
      </li>
    </ul>


    <!-- 订单信息 -->
    <div class="order-info m-padding">

      <div class="info-cell">
        <span>服务类型</span>
        <p>{{modeList[order.mode - 1]}}</p>
      </div>
      <div class="info-cell">
        <span>申请原因</span>
        <p>{{order.reason}}</p>
      </div>
      <div class="info-cell">
        <span>退款方式</span>
        <p>原支付返还</p>
      </div>
      <div class="info-cell ">
        <span>申请时间</span>
        <p>{{order.created_at}}</p>
      </div>
      <div class="info-cell-line"></div>

      <div v-if="order.after_sales_status != 2">
        <div class="info-cell">
          <span>商品退回</span>
          <p>{{order.return_mode == 1 ? '自行送回': '邮寄'}}</p>
        </div>
        <div class="info-cell ">
          <span>联系人</span>
          <p>{{order.contacts || order.order_logistics.name + order.order_logistics.surname}}</p>
        </div>
        <div class="info-cell ">
          <span>联系方式</span>
          <p>{{order.contact_number || order.order_logistics.tel || order.order_logistics.email}}</p>
        </div>
        <div class="info-cell-line"></div>
      </div>
    </div>

    <!-- 为您推荐 -->
    <m-divider title="为您推荐" class="recomnd"></m-divider>
    <goods-columns @cartClick="handleCart" :data="recomnd"></goods-columns>


    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>
    
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>

  </section>
</template>

<script>
import MDivider     from '@/components/zh/m-divider.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getOrderRemGoodsList } from '@/api/en/order.js'
export default {
  name:'AftersaleDetail',
  components:{ MDivider, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      step:4,
      data:8,
      skuModal:false,
      start:false,
      recomnd: [],
      start_dom:null,
      islock: false,
      loading: false,
      page: 1,
      goodsId: '',
      modeList: ['退货退款', '补发商品', '换货', '仅退款']
    }
  },
  created(){
    this.order = JSON.parse(sessionStorage.getItem('aftersale') || '')
    console.log(this.order);
    this.getRecommnd()
  },
  methods:{
     // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getRecommnd()
        }
        this.islock = true
      }
    },
    // 返回上一页
    handleBack(){
      this.$router.go(-1)
    },

    // 完善发货单
    handleComplete(){
      this.$router.push({path:`/zh/aftersale/logistics/1`})
    },

    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getRecommnd() {
      // 获取自定义为你推荐
      this.islock = true
      this.loading  = true
      getOrderRemGoodsList({page: this.page, goods_id: this.$route.params.id}).then(res => {
        this.loading  = false
        if(res) {
          this.recomnd = this.recomnd.concat(res.data.data)
          this.islock   = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './detail.less';
</style>